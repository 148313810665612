import { logoDefault } from '../assets/icons/Icons';

const Logo = () => {
  //Just to minimize amount of objects I got rid of Logo.sass styling file
  const logoStyle = {
    display: "flex",
    alignItems: "center",
    fontWeight: "700"
  }
  const imgStyle = {
    width: "16px",
    height: "16px",
    marginRight: "8px"
  }

  return (
    <div style={logoStyle} className='Logo'>
      <img style={imgStyle} src={logoDefault} alt='logo' />
      <span style={{color: "white"}}>Danylo</span>
    </div>
  );
}

export default Logo;
