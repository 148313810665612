import Title from '../../../components/Title/Title';
import CardList from '../../../components/CardList/CardList';
import data from './data.json';
import useLang from '../../../hooks/useLang';

function Projects() {
  const {lang} = useLang();
  return (
    <div className='Projects container content-block' id='projects'>
      <Title label={data.title[lang]} />
      <CardList content={data.cards} minWidth={280} columnCount={3} maxItems={3} />
    </div>
  );
}

export default Projects;
