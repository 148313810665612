import './Button.sass';
// type = "primary", "secondary"
// The button infuses with primary or secondary color.
// title = string
// url = string
// newPage = bolean

function Button({children, type="secondary", url, newPage}) {
  return (
    <a href={url} target={newPage && "_blank"} rel="noreferrer" className={`Button ${type}`}>
      <button href={url}>
        {children}
        <span></span>
      </button>
    </a>
  );
}

export default Button;
