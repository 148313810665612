import './aboutMe.sass';
import Title from '../../../components/Title/Title';
import aboutMeImg from '../../../assets/images/aboutMeImg.png';
// import Button from '../../components/Button';
import Reveal from '../../../components/Reveal';
import data from './data.json';
import useLang from '../../../hooks/useLang';
import useFilterString from '../../../hooks/useFilterString';

function AboutMe() {
  const {lang} = useLang();
  const aboutMeParagraphs = useFilterString(data[lang].text);
  return (
    <div className="AboutMe container content-block" id='about-me'>
      <div className='leftSide'>
        <Title label={data[lang].title} animType="slide" />
        {aboutMeParagraphs.map((par, i) => (<p key={i}><Reveal type="slide" key={i}>{par}</Reveal></p>))}
        {/*<Reveal type="slide"><Button label={data[lang].buttonLabel} type={"primary"} url={"/about-me"} /></Reveal>*/}
      </div>
      <img src={aboutMeImg} alt='Header Ilustration' />
    </div>
  );
}

export default AboutMe;
