import { createContext, useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import useLoading from '../hooks/useLoading';

const LangContext = createContext();

const LangProvider = ({ children }) => {
  const [langBefore, setLangBefore] = useState(null);
  const [lang, setLang] = useState(null);
  const {isLoading, startLoading, endLoading, loaderRun} = useLoading();
  const navigate = useNavigate();

  const switchLangTo = (newLang) => setLangBefore(newLang);
  const noAnimSwitchLangTo = (newLang) => setLang(newLang);

  useEffect(() => {
    // Redirecting user to selected page if:
    // App no longer loading
    // The loader is on screen and no longer animating
    // langBefore !== null to ensure that this function runs only when language is changed
    if(isLoading && !loaderRun && langBefore !== null) {
      navigate(langBefore);
      setLang(langBefore);
      endLoading();
      setLangBefore(null);
      console.log('The language is changed!');
    }
  }, [loaderRun]);

  useEffect(() => {
    if(!isLoading && langBefore !== null) {
      startLoading();
    }
  }, [langBefore]);

  return (
    <LangContext.Provider value={{ lang, switchLangTo, noAnimSwitchLangTo }}>
      {children}
    </LangContext.Provider>
  );
};

export { LangContext, LangProvider };