import React, { useState, useEffect, useRef, createContext, useContext } from 'react';
import { motion } from 'framer-motion';
import useLang from '../../hooks/useLang';
import './Navbar.sass';
import data from './data.json';
import Hash from '../Hash';
import Logo from '../Logo';
import SocialBar from '../SocialBar/SocialBar';
import { dropdownArrow } from '../../assets/icons/Icons';
import Reveal from '../Reveal';
import useWindowSize from '../../hooks/useWindowSize';
import useScroll from '../../hooks/useScroll';
import useLoading from '../../hooks/useLoading';

const NavbarContext = createContext();

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMenuAnimating, setIsMenuAnimating] = useState(false);
  const windowSize = useWindowSize();
  const smallWindowSize = windowSize.width <= 720;
  const { enableScroll, disableScroll } = useScroll();

  const toggleMenu = () => {
    if (!isMenuAnimating) {
      setIsMenuAnimating(true);
      setIsMenuOpen(!isMenuOpen);
    }
  };

  const startMenuAnim = () => setIsMenuAnimating(true);
  const endMenuAnim = () => setIsMenuAnimating(false);

  useEffect(() => { !smallWindowSize && setIsMenuOpen(false) }, [windowSize, smallWindowSize]);

  useEffect(() => { smallWindowSize && isMenuOpen ? disableScroll() : enableScroll() }, [isMenuOpen]);

  // Render the Navbar component
  return (
    <NavbarContext.Provider value={{ isMenuOpen, isMenuAnimating, toggleMenu, startMenuAnim, endMenuAnim }}>
      <Reveal type="fade" delay={1.2}>
        <nav className="Navbar">
          {/* Desktop version */}
          <div className='desktop'>
            <div className='container'>
              <Logo />
              <NavigationLinks/>
            </div>
          </div>

          {/* Mobile version */}
          <div className={`mobile ${isMenuOpen ? 'open' : ''}`}>
            <div className='container'>
              <Logo />
              <button className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <span id="line1" />
                <span id="line2" />
              </button>
              <NavigationLinks/>
              <SocialBar iconSize={64} />
            </div>
          </div>
        </nav>
      </Reveal>
    </NavbarContext.Provider>
  );
}

// Subcomponent for rendering navigation links
const NavigationLinks = () => {
  const { lang } = useLang();
  const { isMenuOpen, startMenuAnim, endMenuAnim } = useContext(NavbarContext);

  const linkAnimDuration = 0.24;
  const delayPerLink = 0.05;

  return (
    lang && 
    <motion.ul
      className='NavLinks'
      initial={isMenuOpen ? 'hidden' : 'visible'}
      animate={isMenuOpen ? 'visible' : 'hidden'}
      onAnimationStart={() => startMenuAnim()}
      onAnimationComplete={() => setTimeout(endMenuAnim(), linkAnimDuration)}
    >
      {data[lang].map((item, i) => (
        <Link
          key={i}
          delay={isMenuOpen ? 0.2 + i * delayPerLink : 0}
          linkAnimDuration={linkAnimDuration}
          url={item.url}
        >
          <Hash />{item.label}
        </Link>
      ))}
      <Link
        key='Dropdown-link'
        delay={0.2 + data[lang].length * delayPerLink}
        linkAnimDuration={linkAnimDuration}
        offToggleMenu={true}
      >
        <Dropdown />
      </Link>
      
    </motion.ul>
  );
};

const Link = ({ children, delay, url, linkAnimDuration, offToggleMenu = false, onClick }) => {
  const { isMenuOpen, toggleMenu } = useContext(NavbarContext);
  return (
    <motion.li
      className="hash-link"
      href={url}
      onClick={() => (!offToggleMenu ? toggleMenu() : null, onClick && onClick())}
      variants={{
        visible: {
          opacity: 1,
          transform: 'translateY(0)',
        },
        hidden: {
          opacity: 0,
          transform: 'translateY(-10px)',
        },
      }}
      transition={isMenuOpen && {
        delay: delay,
        duration: linkAnimDuration,
      }}
    >
      {children}
    </motion.li>
  )
}

function Dropdown() {
  const { toggleMenu } = useContext(NavbarContext);
  const { lang, switchLangTo } = useLang();
  const { isLoading } = useLoading();

  const linkAnimDuration = 0.24;
  const delayPerLink = 0.1;

  const [isDDOpen, setDDIsOpen] = useState(false);
  const DDRef = useRef(null);

  return (
    <motion.div
      className={`Dropdown ${isDDOpen && 'open'}`}
      ref={DDRef}
      initial={isDDOpen ? 'hidden' : 'visible'}
      animate={isDDOpen ? 'visible' : 'hidden'}
      onMouseOver={() => !isLoading && setDDIsOpen(true)}
      onMouseOut={() => setDDIsOpen(false)}
    >
      <motion.div className="dropbtn">
        {lang.toUpperCase()}
        <motion.img
          src={dropdownArrow}
          alt='Chevron Down Icon'
          className="arrow"
          variants={{
            visible: { transform: 'rotate(180deg)' },
            hidden: { transform: 'rotate(0)' },
          }}
        />
      </motion.div>
      <ul className='dropdown-content'>
        {Object.keys(data).map((item, i) => (
          // Exclude the item where item === lang
          item !== lang && (
            <Link
              key={i}
              delay={isDDOpen ? 0.2 + i * delayPerLink : 0}
              linkAnimDuration={linkAnimDuration}
              onClick={() => {toggleMenu(); switchLangTo(item);}}
            >
              <span>
                {item.toUpperCase()}
              </span>
            </Link>
          )
        ))}
      </ul>
    </motion.div>
  );
}

export default Navbar;