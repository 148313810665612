import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.sass';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import SmoothScroll from './components/SmoothScroll';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter as Router } from 'react-router-dom';
import { LangProvider } from './context/LangContext';
import { StatusProvider } from './context/StatusProvider';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <StatusProvider>
        <LangProvider>
          <Navbar/>
          <SmoothScroll>
            <App />
          </SmoothScroll>
        </LangProvider>
      </StatusProvider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();