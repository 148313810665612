import { useContext } from 'react';
import { StatusContext } from '../context/StatusProvider';

// Custom hook to consume the LoadingContext
const useLoading = () => {
  const context = useContext(StatusContext);

  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }

  const {
    isLoading, startLoading, endLoading,
    loaderRun, startLoadAnim, endLoadAnim
  } = context;

  return {
    isLoading, startLoading, endLoading,
    loaderRun, startLoadAnim, endLoadAnim
  };
};

export default useLoading;
