import './Quote.sass';
import quotationMark from '../../../assets/icons/quotation-mark.svg';
import Reveal from '../../../components/Reveal';
import useLang from '../../../hooks/useLang';
import data from './data.json';

function Quote() {
  const {lang} = useLang();
  const content = data[lang];
  return ( 
    <div className="Quote container content-block">
      <Reveal type="fadeToTop">
        <div className='main-block'>
          <img src={quotationMark} alt="quatationMarkTop" />
          <img src={quotationMark} alt="quatationMarkBottom" />
          <h4>{content.quote}</h4>
        </div>

        <div className="sub-block">
          <h4>{content.author}</h4>
        </div>
      </Reveal>
    </div>
  );
}

export default Quote;
