import './Skills.sass';
import skillsImg from '../../../assets/images/skillsImg.svg';
import CardList from '../../../components/CardList/CardList';
import Reveal from '../../../components/Reveal';
import data from './data.json';

function Skills() {
  return (
    <div className="Skills container content-block">
      <Reveal type="fade" delay={1}>
        <div className='leftSide'>
          <img src={skillsImg} alt='skillsImg'/>
        </div>
      </Reveal>
      <div className='rightSide'>
        <CardList sublist={true} content={data} minWidth={150} columnCount={3} />
      </div>
    </div>
  );
}

export default Skills;
