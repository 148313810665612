import '../index.sass';
// See Hash styling in index.sass

const Hash = () => {
  return (
    <span className="Hash">#</span>
  );
}

export default Hash;
