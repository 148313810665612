import './Contacts.sass';
import Title from '../../../components/Title/Title';
import Reveal from '../../../components/Reveal';
import { whatsapp, email } from '../../../assets/icons/Icons';
import data from './data.json';
import useLang from '../../../hooks/useLang';
import Button from '../../../components/Button/Button';
import enResume from '../../../assets/resume/en.pdf';
import deResume from '../../../assets/resume/de.pdf';

function Contacts() {
  const {lang} = useLang();
  const content = data[lang];
  const resume = {
    'en': enResume,
    'de': deResume,
    'ua': enResume
  }
  return (
    <div className="Contacts container" id='contacts'>
      <div className='title'>
        <Title label={content.title} animType="slide" />
      </div>
      <div className='left-side'>
        <Reveal type="slide">
          <p>{content.text}</p>
        </Reveal>
        <Reveal type="slide">
          <Button type='primary' url="">
            <a href={resume[lang]} download={content.cvLabel}>
              {content.buttunLabel}
            </a>
          </Button>
        </Reveal>
      </div>
      <div className='gap' />
      <Reveal type="slide">
        <div className='box'>
          <p>{content.contactLabel}</p>

          <img src={whatsapp} alt="Whatsapp" />
          <a href="https://api.whatsapp.com/send?phone=4915172620765" target='_blank' rel="noreferrer">@danylokhobta</a>

          <img src={email} alt="Email" />
          <a href="mailto:danylokhobta@gmail.com" target='_blank' rel="noreferrer">danylokhobta@gmail.com</a>
        </div>
      </Reveal>
    </div>
  );
}

export default Contacts;
