import { useEffect } from 'react';
import Header from './Header/Header';
import Quote from './Quote/Quote';
import Projects from './Project/Projects';
import Skills from './Skills/Skills';
import AboutMe from './AboutMe/aboutMe';
import Contacts from './Contacts/Contacts';
import Footer from './Footer/Footer';
import useLang from '../../hooks/useLang';

const HomeView = ({pageLang}) => {
  const {lang, noAnimSwitchLangTo} = useLang();
  useEffect(() => noAnimSwitchLangTo(pageLang))

  return (
    lang !== null &&
      <div className="HomeView">
        <Header />
        <Quote />
        <Projects />
        <Skills />
        <AboutMe />
        <Contacts />
        <Footer />
      </div>
  );
}

export default HomeView;