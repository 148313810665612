import React, { useState, useEffect } from 'react';

function useFilterString(string) {
  const [filteredArray, setFilteredArray] = useState([]);

  useEffect(() => {
    if (string) {
      const stringArray = string.split(/(<span>.*?<\/span>)|\s*<br>\s*/).filter(str => str).filter(str => str);
      setFilteredArray(stringArray.map((str, index) => {
        if (str) {
          if (str.startsWith && str.startsWith('<span>') && str.endsWith('</span>')) {
            const innerText = str.substring(6, str.length - 7); // Removing <span> and </span> tags
            return <span key={index}>{innerText}</span>;
          } else {
            return str || null;
          }
        }
      }));
    }
  }, [string]);
  return filteredArray;
}

export default useFilterString;
