import './Title.sass';
import Hash from '../Hash';
import Reveal from '../Reveal';

function Title({label, animType="fade"}) {
  return (
    <div className="Title">
      <Reveal type={animType} delay={0.5}>
        <h4><Hash />{label}</h4>
        <div className='line'></div>
      </Reveal>
    </div>
  );
}

export default Title;
