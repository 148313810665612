import { useEffect, useRef, useState } from "react";
import useWindowSize from "../hooks/useWindowSize";
import useLoading from "../hooks/useLoading";

const SmoothScroll = ({ children }) => {
  // 1.
  const { loaderRun, isLoading } = useLoading();
  const windowSize = useWindowSize();
  const [disableSmoothScroll, setDisableSmoothScroll] = useState('ontouchstart' in window || navigator.maxTouchPoints);

  //2.
  const scrollingContainerRef = useRef();

  // 3.
  const data = {
    ease: 8.5,
    current: 0,
    previous: 0,
    rounded: 0
  };

  // 4.
  const setBodyHeight = () => {
    if(!disableSmoothScroll) {
      document.body.style.height = `${
        scrollingContainerRef.current.getBoundingClientRect().height
      }px`;
      console.log("height set to:" , scrollingContainerRef.current.getBoundingClientRect().height)
    }
  };

  useEffect(() => {
    setBodyHeight();
    setTimeout(setBodyHeight, 1000);
  }, [windowSize, loaderRun]);

  // 5.
  const smoothScrollingHandler = () => {
    if(!disableSmoothScroll) {
      data.current = window.scrollY;
      data.previous += (data.current - data.previous) * data.ease / 100;
      data.rounded = Math.round(data.previous * 100) / 100;

      scrollingContainerRef.current.style.transform = `translateY(-${data.rounded}px)`;

      // Recursive call
      requestAnimationFrame(() => smoothScrollingHandler());
    }
  };

  useEffect(() => {
    requestAnimationFrame(() => smoothScrollingHandler())
  }, [disableSmoothScroll]);

  // 6.
  const smoothScrollTo = (targetPosition) => {
    const startPosition = window.scrollY;
    const distance = targetPosition - startPosition;
    const duration = 700; // Adjust the duration as needed
    
    let start = null;
  
    const step = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
  
      window.scrollTo(0, easeInOutCubic(progress, startPosition, distance, duration));
  
      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    };
  
    window.requestAnimationFrame(step);
  };
  
  const easeInOutCubic = (t, b, c, d) => {
    // t: current time, b: beginning value, c: change in value, d: duration
    t /= d / 2;
    if (t < 1) return c / 2 * t * t * t + b;
    t -= 2;
    return c / 2 * (t * t * t + 2) + b;
  };

  // 7.
  const handleLinkClick = (event) => {
    const { target } = event;
    const href = target.getAttribute('href');
    console.log('Adressing you to: ', href)
  
    // Check if it's an anchor link and handle it
    if (href && href.startsWith('#')) {
      event.preventDefault();
      const elementId = href.slice(1);
      const element = document.getElementById(elementId);
      if (element) {
        const offsetTop = element.getBoundingClientRect().top + window.scrollY;
        smoothScrollTo(offsetTop);
      }
    }
  };

  useEffect(() => {
    if(!isLoading){
      const elements = document.querySelectorAll('.hash-link, a');
      if(elements){
        elements.forEach(element => {
          element.addEventListener('click', handleLinkClick);
        });
        return () => {
          elements.forEach(element => {
            element.removeEventListener('click', handleLinkClick);
          });
        };
      }
    }
  }, [isLoading]);

  return (
    <div>
      {
        disableSmoothScroll ?
          <div>{children}</div> :
          <div
            className="SmoothScroll"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: 'hidden',
              zIndex: 1,
            }}
          >
            <div ref={scrollingContainerRef}>{children}</div>
          </div>
      }
    </div>
  );
};

export default SmoothScroll;