import { Routes, Route } from 'react-router-dom';
import HomeView from './homeView/HomeView';
import useLang from '../hooks/useLang';

const App = () => {
  const {lang} = useLang();
  const langs = ['en', 'de', 'ua'];

  return (
    <div className='App'>
      <Routes>
        <Route index element={<HomeView pageLang='en' />} />
        <Route path='en' element={<HomeView pageLang='en' />} />
        <Route path='de' element={<HomeView pageLang='de' />} />
        <Route path='ua' element={<HomeView pageLang='ua' />} />
      </Routes>
    </div>
  );
};

export default App;