import React from 'react';

import './Header.sass';
import headerImg from '../../../assets/images/headerImg.png';
import Button from '../../../components/Button/Button';
import Reveal from '../../../components/Reveal';
import data from './data.json';
import useLang from '../../../hooks/useLang';
import useFilterString from '../../../hooks/useFilterString';

const Header = () => {
  const {lang} = useLang();
  const content = data[lang];
  const headerText = useFilterString(content.header);
  const subText = content.p;
  const buttonLabel = content.buttonLabel;
  const status = useFilterString(content.status);

  return (
    <header className='Header container content-block' id='home'>
      <div className='left-side'>
        <Reveal type="slide" delay={0}><h2>{headerText}</h2></Reveal>
        <Reveal type="slide" delay={0}><p>{subText}</p></Reveal>
        <Reveal type="slide" delay={0}><Button type="primary" url="#contacts">{buttonLabel}</Button></Reveal>
      </div>
      <div className='right-side'>
        <Reveal type="fade" delay={0.8} duration={1}>
          <img src={headerImg} alt='hederImg' />
          <div className='status'>
            <div className='square'></div>
            <p>{status}</p>
          </div>
        </Reveal>
        
      </div>
    </header>
  );
}

export default Header;
