import { useContext } from 'react';
import { StatusContext } from '../context/StatusProvider';

const useScroll = () => {
  const context = useContext(StatusContext);

  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  
  const { scrollStatus, enableScroll, disableScroll } = context;

  return { scrollStatus, enableScroll, disableScroll };
};

export default useScroll;