import './CardList.sass';
import Button from '../Button/Button';
import React, { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';
import useLang from '../../hooks/useLang';

const CardList = ({ content, minWidth, columnCount, sublist, maxItems }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  const slideControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls, slideControls]);

  return (
    <div className='CardList' ref={ref} style={{ columns: `${columnCount} ${minWidth}px` }}>
      {content.map((card, i) => {
        if (!maxItems || i < maxItems) {
          return (
            <Card
              key={i}
              index={i}
              content={card}
              minWidth={minWidth}
              columnCount={columnCount}
              sublist={sublist}
              mainControls={mainControls}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

const Card = ({
  index, sublist, mainControls, content
}) => {
  const { lang } = useLang();
  const { img, tags, url, subUrl, label, subLabel } = content;
  const { title, description } = content.text[lang];

  return (
    <motion.div
      className="Card"
      variants={{
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, delay: index * 0.2 + 0.3 }}
    >
      {img && !sublist && <img src={require(`../../assets/images/${img}`)} alt='cardImg' />}
      {title && sublist && <p className="subtitle">{title}</p>}
      {tags && <p className='tags'>{tags}</p>}
      {!sublist &&
        <div className='content'>
          <h4>{title}</h4>
          <p>{description}</p>
          <Button type="primary" url={url} newPage={true}>{`${label} <~>`}</Button>
          {subUrl && <Button type="secondary" url={subUrl} newPage={true}>{`${subLabel} >=`}</Button>}
        </div>
      }
    </motion.div>
  );
}

export default CardList;
