import React, { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';
import useLoading from '../hooks/useLoading';

// GUIDE
// type = string. Could be "fade", "slide", "fadeToTop".
// delay = number. Number of seconds of delay before animation.

const Reveal = ({ children, type, delay=0.3, duration=0.5 }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();
  const slideControls = useAnimation();
  const {isLoading, loaderRun} = useLoading();

  const chosenVariant = (animType) => {
    if(animType === "slide") {
      return {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
      }
    } else if(animType === "fadeToTop") {
      return {
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      }
    } else {
      return {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
      }
    }
  }

  useEffect(() => {
    if (isInView && !loaderRun && !isLoading) {
      mainControls.start("visible");
      if (type === "slide") {
        slideControls.start("start");
        setTimeout(() => {
          slideControls.start("finish");
        }, 400)
      }
    }
  }, [isInView, loaderRun]);

  return (
    <div ref={ref} className='Reveal' style={{position: 'relative', display: 'inline-block'}}>
      <motion.span
        className='reveal-child'
        variants={chosenVariant(type)}
        initial="hidden"
        animate={mainControls}
        transition={
          type === 'slide' ?
          { duration: 0.5, delay: (0.5 + delay) } :
          { duration: duration, delay: delay }
        }
      >
        {children}
      </motion.span>

      {type === 'slide' && 
        <motion.span
          variants={{
            start: { right: 0 },
            finish: { left: "100%"}
          }}
          initial="hidden"
          animate={slideControls}
          transition={{ duration: 0.5, delay: delay, ease: "easeInOut" }}
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: "100%",
            background: "#78a5dd",
            zIndex: 20,
          }}
        />
      }
    </div>
  );
};

export default Reveal;
