import { createContext, useState, useEffect } from 'react';
import LoaderView from '../components/LoaderView/LoaderView';

const StatusContext = createContext();

const StatusProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const startLoading = () => setIsLoading(true);
  const endLoading = () => setIsLoading(false);

  const [loaderRun, setLoaderRun] = useState(false);
  const startLoadAnim = () => setLoaderRun(true);
  const endLoadAnim = () => setLoaderRun(false);

  const [scrollStatus, setScrollStatus] = useState(false);
  const enableScroll = () => setScrollStatus(true);
  const disableScroll = () => setScrollStatus(false);

  
  useEffect(() => {
    !loaderRun && !isLoading && setScrollStatus(true);
    loaderRun && isLoading && setScrollStatus(false);
  }, [loaderRun]);
  
  useEffect(() => {
    const blockTouch = (e) => e.preventDefault();
    if(scrollStatus){
      document.body.style.overflowY = 'scroll';
      document.removeEventListener('touchmove', blockTouch);
    } else {
      document.body.style.overflowY = 'hidden';
      document.addEventListener('touchmove', blockTouch, { passive: false });
      return () => document.removeEventListener('touchmove', blockTouch);
    }
  }, [scrollStatus]);
  
  useEffect(() => {
    const appLoad = () => {
      setTimeout(() => {
        endLoading();
        console.log("The App is loaded!");
      }, 400);
    };
    if (document.readyState === 'complete') {
      // Code to execute when the window is already loaded
      appLoad();
    } else {
      // Code to execute while the window is loading
      window.addEventListener('load', appLoad);
      return () => window.removeEventListener('load', appLoad);
    }
  }, []);

  return (
    <StatusContext.Provider value={{
      isLoading, startLoading, endLoading,
      loaderRun, startLoadAnim, endLoadAnim,
      scrollStatus, enableScroll, disableScroll
    }}>
      <LoaderView />
      {children}
    </StatusContext.Provider>
  );
};

export { StatusContext, StatusProvider};