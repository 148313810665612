import './SocialBar.sass';
import { github, linkedin, whatsapp } from '../../assets/icons/Icons';

// vertical = bolean 
// If true, sets the flex direction to 'column' making the layout vertical.
// iconSize = number
// the size of the icon will be height: NUMBER, width: NUMBER

const SocialBar = ({ vertical, iconSize }) => {

  const socialBarItems = [
    { label: github, url: 'https://github.com/danylokhobta' },
    { label: whatsapp, url: 'https://api.whatsapp.com/send?phone=4915172620765' },
    { label: linkedin, url: 'https://linkedin.com/in/danylokhobta' },
  ];

  return (
    <div className='SocialBar' style={{ flexDirection: vertical ? 'column' : undefined }}>
      {socialBarItems.map((item, index) => (
        <a key={index} href={item.url} target='_blank' rel="noreferrer">
          <img src={item.label} alt={item.label} style={{height: `${iconSize}px`, width: `${iconSize}px`}} />
        </a>
      ))}
    </div>
  );
}

export default SocialBar;