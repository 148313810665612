import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import './LoaderView.sass';
import useLoading from '../../hooks/useLoading';
import useWindowSize from '../../hooks/useWindowSize';

const LoaderView = () => {

  const windowSize = useWindowSize();
  const horizLoader = windowSize.width < 720;

  const [columns, setColumns] = useState([]);
  const columnAmount = 5;
  const colAnimDuration = 0.4;

  const { isLoading, loaderRun, startLoadAnim, endLoadAnim } = useLoading();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [startIn, setStartIn] = useState(null);


  useEffect(() => {
    const newColumns = [];
    for (let i = 0; i < columnAmount; i++) {
      newColumns.push(
        <motion.div
          key={i}
          className='column'
          variants={{
            in: {
              height: horizLoader ? '100%' : '100vh',
              width: horizLoader ? '100%' : '100%'
            },
            out: {
              height: horizLoader ? '100%' : '0vh',
              width: horizLoader ? '0' : '100%'
            },
          }}
          transition={{
            delay: i * 0.1,
            duration: colAnimDuration,
          }}
        />
      );
    }
    setColumns(newColumns);
  }, [columnAmount, horizLoader]);

  useEffect(() => {
    if(!loaderRun) {
      if (isFirstLoad && !isLoading) {
        setIsFirstLoad(false);
        setStartIn(true);
      }

      if (!isFirstLoad) {
        if (isLoading) {
          setStartIn(false);
        } else {
          setStartIn(true);
        }
      }
    }
  }, [isLoading, loaderRun, isFirstLoad]);

  return (
    <motion.div
      className="LoaderView"
      initial={startIn === null ? 'in' : startIn ? 'in' : 'out'}
      animate={startIn === null ? 'in' : startIn ? 'out' : 'in' }
      onAnimationStart={() => startLoadAnim()}
      onAnimationComplete={() => setTimeout(endLoadAnim(), colAnimDuration)}
    >
      {columns}
    </motion.div>
  );
};

export default LoaderView;
